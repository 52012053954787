import initialState from 'reducers/initialState';
import * as types from 'actions/actionTypes';

export default function(state = initialState.reducerInternalCurrentProduct, action) {
  switch (action.type) {
    case types.INTERNAL_PRODUCT_SELECT:
      return {
        currentItem: action.payload[0],
        isItemSelected: true,
      }
    case types.INTERNAL_PRODUCT_DESELECT:
      return {
        currentItem: false,
        isItemSelected: false
      }
    default:
      return state;
  }
}
