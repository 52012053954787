import initialState from 'reducers/initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerItems, action) {

  
  switch (action.type) {
    case types.ITEM_LIST_LOCATION_FULFILLED:
      return {
        itemListLocation: action.payload.data.results,
        isItemsLoaded: action.payload.data.success, 
      }   
    default:
      return state;
  }
}
//reason: ( action.payload.response.data.itemList.reason || action.payload.response.data.itemDef.reason )
