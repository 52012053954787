import initialState from 'reducers/initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerItems, action) {

  switch (action.type) {
    case types.ITEM_LIST_FULFILLED:
      return {
        itemList: action.payload.data.itemList,
        isItemsLoaded: true,
        itemDef: action.payload.data.itemDef,
        defaultProp: action.payload.data.defaultProp,
      }
    case types.ITEM_LIST_REJECTED:
      return {
        itemList: [],
        isItemsLoaded: false,
        itemDef: [],
        defaultProp: [],
        reason: action.payload.response ? action.payload.response.data : 'No se pudo obtener datos, porfavor intente denuevo'
      }
    case types.ITEM_LIST:
      return state;

    case types.EDIT_WAS_CLICKED:
      return Object.assign({}, state, { editWasClicked: action.payload })
    default:
      return state;
  }
}
//reason: ( action.payload.response.data.itemList.reason || action.payload.response.data.itemDef.reason )
