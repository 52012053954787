import initialState from '../initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerCurrentItemCatalogAddParams, action) {
    switch (action.type) {
        case types.ITEM_LIST_CATALOG_ADDPARAMS_FULFILLED:
            return {
                currentItemCatalogAddParams: action.payload ? action.payload.data.results : [{}],
                currentItemCatalogAddParamsLoaded: true
            }
        default:
            return state;
    }
}
