export default {
  userProfile: { isAuthenticated: false, token: '', user: {} },
  appStatus: { loading: null },
  reducerItems: { itemList: [], isItemsLoaded: false, itemDef: [] },
  reducerValidCenter: { validCenter: false, isValidCenterLoaded: false },
  reducerValidProduct: { validProduct: false, isValidProductLoaded: false },
  reducerUnitMeasure: { unitMeasure: false, isUnitMeasureLoaded: false },
  reducerEmails: { emailList: false, isEmailsLoaded: false },
  reducerHierarchies: { hierarchyList: false, isHierarchiesLoaded: false },
  reducerCurrentItem: {
    currentItem: false, isItemSelected: false, isNewItem: false,
    itemAdded: false, isItemAdded: false,
    itemUpdated: false, isItemUpdated: false,
    itemDeleted: false, isItemDeleted: false
  },
  reducerFile: { isUploadModal: false, isUploadingFile: false, isDownloadingFile: false },
  reducerHomologationProductList: { internalProductList: [], externalProductList: [], productListLoaded: false },
  reducerInternalCurrentProduct: { currentItem: false, isItemSelected: false },
  reducerExternalCurrentProduct: { currentItem: false, isItemSelected: false },
  reducerHomologationProcess: {
    stepProcess: 'INIT', external_selected: false, internal_selected: false,
  },
  reducerTasks: { taskList: [] },
  reducerItemListCampTaskUserTypes: { ItemListCampTaskUserTypes: [] },
  reducerItemListCampFollowUserTypes: { ItemListCampFollowUserTypes: [] },
  reducerItemListManHours: { ItemListManHours: [] },
  reducerItemListManufacturers: { ItemListManufacturers: [] },
  reducerItemListManufacturersAvailableToAdd: { ManufacturersAvailableToAdd: [] },
  reducerItemListCatalog: { ItemListCatalog: [] },
  reducerCurrentItemCatalogAddParams: { CurrentItemCatalogAddParams: [] },
  reducerItemSaleTypes: { ItemListSaleTypes: [] },
  reducerAlters: { alterList: [] },
  reducerCampaignPerfectStorePillar: { ItemListPillar: [] },
  reducerCampaignPerfectStoreSubPillar: { ItemListSubPillar: [] },
  reducerItemListCatalogDimensions: { ItemListCatalogDimensions: [] },
  reducerItemListCfgManufacturersToLoad: { ItemListCfgManufacturersToLoad: [] },
  reducerItemListManufacturerUserTypes: { ItemListManufacturerUserTypes: [] },
  reducerItemListManufacturerImgQualityLevel: { ItemListManufacturerImgQualityLevel: [] },
  reducerItemListTypeCampaign: { ItemListTypeCampaign: [] },
  reduceritemListCampaignTypeGroupVisibility: { itemListCampaignTypeGroupVisibility: [] },
  reducerItemListTaskUserRoles: { ItemListTaskUserRoles: null }
}
