import initialState from '../../reducers/initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerItemListManufacturerUserTypes, action) {
    switch (action.type) {
        case types.ITEM_LIST_MANUFACTURER_USER_TYPES_FULFILLED:
            return {
                ItemListManufacturerUserTypes: action.payload ? action.payload.data.results : [{}],
                isItemListManufacturerUserTypesLoaded: true,
            }
        default:
            return state;
    }
}
