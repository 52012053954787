import initialState from 'reducers/initialState';
import * as types from 'actions/actionTypes';

export default function(state = initialState.reducerExternalCurrentProduct, action) {
  switch (action.type) {
    case types.EXTERNAL_PRODUCT_SELECT:
      return {
        currentItem: action.payload[0],
        isItemSelected: true,
      }
    case types.EXTERNAL_PRODUCT_DESELECT:
      return {
        currentItem: false,
        isItemSelected: false
      }
    case types.BLOCK_EXTERNAL_PRODUCT_FULFILLED:
      return {
        currentItem: action.payload.data,
        isItemSelected: true,
      }
    case types.BLOCK_EXTERNAL_PRODUCT_REJECTED:
      return {
        currentItem: state.currentItem,
        isItemSelected: true,
        reason: action.payload.response.data.reason,
      }
    case types.NEW_HOMOLOGATION_FULFILLED:
      return {
        itemWasHomologated:true,
        currentItem: action.payload.data,
        isItemSelected: true,
      }
    case types.NEW_HOMOLOGATION_REJECTED:
      return {
        itemWasHomologated:false,
        currentItem: state.currentItem,
        isItemSelected: true,
        reason: action.payload.response.data.reason,
      }
    case types.DELETE_HOMOLOGATION_FULFILLED:
      return {
        itemHomologationDeleted:true,
        currentItem: action.payload.data,
        isItemSelected: true,
        Codigo_interno: state.currentItem.Codigo_interno 
      }
    case types.DELETE_HOMOLOGATION_REJECTED:
      return {
        itemHomologationDeleted:false,
        currentItem: state.currentItem,
        isItemSelected: true,
        reason: action.payload.response.data.reason,
      }
    default:
      return state;
  }
}
