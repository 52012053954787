import initialState from '../initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerItemListTaskUserRoles, action) {
    switch (action.type) {
        case types.ITEM_LIST_CAMP_TASK_USER_ROLES_FULFILLED:
            return {
                ItemListTaskUserRoles: action.payload ? action.payload.data : [{}],
            }
        default:
            return state;
    }
}
