import { Map, OrderedMap } from 'immutable'
import * as types from '../../actions/actionTypes'

let nextId = 0

const emptyFilters = OrderedMap()

const initialState = Map({
  filters: emptyFilters,
  fields: [],
  fileType: 'xlsx'
})

function addFilter (state) {
  return state.update('filters', filters => {
    return filters.set(++nextId, state.get('fields').find(f => f.filter))
  })
}

function removeFilter (state, filterId) {
  return state.deleteIn(['filters', filterId])
}

function clearFilters (state) {
  return state.set('filters', emptyFilters)
}

function changeFilterField (state, filterId, fieldName) {
  return state.setIn(
    ['filters', filterId],
    state.get('fields').find(f => f.value === fieldName)
  )
}

function changeFilterValue (state, filterId, value) {
  let filter = Object.assign({}, state.getIn(['filters', filterId]))
  filter.currentValue = value
  return state.setIn(['filters', filterId], filter)
}

function setFields (state, fields) {
  return state.set('fields', fields)
}

function setFileType (state, type) {
  return state.set('fileType', type)
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.ADD_FILTER:
      return addFilter(state)

    case types.REMOVE_FILTER:
      return removeFilter(state, action.filterId)

    case types.CLEAR_FILTERS:
      return clearFilters(state)

    case types.CHANGE_FILTER_FIELD:
      return changeFilterField(state, action.filterId, action.fieldName)

    case types.CHANGE_FILTER_VALUE:
      return changeFilterValue(state, action.filterId, action.value)

    case types.FETCH_FIELDS_FULFILLED:
      return setFields(state, action.payload.data)

    case types.CHANGE_EXPORTING_FORMAT:
      return setFileType(state, action.fileType)

    default:
      return state
  }
}
