import initialState from 'reducers/initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerCurrentItem, action) {
  switch (action.type) {
    case types.ACCEPT_LOCATION_FULFILLED:
      return {
        locationAccepted: true,
      }
    case types.REJECT_LOCATION_FULFILLED:
      return {
        locationAccepted: true,
      }
    default:
      return state;
  }
}
