import initialState from '../../reducers/initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerItemListCampFollowUserTypes, action) {
    switch (action.type) {
        case types.ITEM_LIST_MAN_HOURS_FULFILLED:
            return {
                itemListManHours: action.payload ? action.payload.data.itemList : [{}],
            }
        default:
            return state;
    }
}
