import React, { Component } from "react"
import { connect } from 'react-redux'
import { Route, withRouter, Switch } from "react-router-dom"
import { library } from '@fortawesome/fontawesome-svg-core'
import Loadable from 'react-loadable';
import Alert from 'react-s-alert'

import logo from 'styles/datamind_logo.png'

//import runtimeEnv from '@mars/heroku-js-runtime-env';

import { authLogin } from 'actions/authActions'

import packageJson from '../package.json'

import { renderSpinner, renderError, ErrorPage } from "lib/helpers"
import { Container, Row, Col } from 'reactstrap'
import {
  faUpload, faPlus, faToggleOn, faToggleOff, faDownload, faShoppingBasket, faTabletAlt,
  faPlusSquare, faMinusSquare, faBuilding, faClipboardList, faFileAlt, faCheck,
  faExclamationTriangle, faTrashAlt, faUndoAlt, faSave, faSpinner, faTimes, faMobileAlt,
  faLock, faUnlock, faCheckDouble, faFilter, faSearch, faInfoCircle, faListAlt, faUndo, faMinus,
  faAngleDoubleLeft, faAngleDoubleRight
} from '@fortawesome/free-solid-svg-icons'

import CacheBuster from './components/common/cacheBuster';


library.add(
  faUpload, faPlus, faToggleOn, faToggleOff, faDownload, faShoppingBasket, faTabletAlt,
  faPlusSquare, faMinusSquare, faBuilding, faClipboardList, faFileAlt, faCheck,
  faExclamationTriangle, faTrashAlt, faUndoAlt, faSave, faSpinner, faTimes, faMobileAlt,
  faLock, faUnlock, faCheckDouble, faFilter, faSearch, faInfoCircle, faListAlt, faUndo, faMinus,
  faAngleDoubleLeft, faAngleDoubleRight
)

const env = process.env;

//require('dotenv').config()

//TODO:
const TaskManagerEmailRecs = Loadable({
  loader: () => import(/* webpackChunkName: 'taskManagerEmailRecs' */  './pages/taskManagerEmailRecs'),
  loading: renderSpinner,
});

const TaskManager2EmailRecs = Loadable({
  loader: () => import(/* webpackChunkName: 'taskManagerEmailRecs' */  './pages/taskManager2EmailRecs'),
  loading: renderSpinner,
});

const CenterAttributes = Loadable({
  loader: () => import(/* webpackChunkName: 'centerAttributes' */  './pages/centerAttributes'),
  loading: renderSpinner,
});

const TaskManagerRoutes = Loadable({
  loader: () => import(/* webpackChunkName: 'taskManagerRoutes' */  './pages/taskManagerRoutes'),
  loading: renderSpinner,
});

const AttributeManager = Loadable({
  loader: () => import(/* webpackChunkName: 'attributeManager' */  './pages/attributeManager'),
  loading: renderSpinner,
});

const ProductAttributes = Loadable({
  loader: () => import(/* webpackChunkName: 'productAttributes' */  './pages/productAttributes'),
  loading: renderSpinner,
});

const ProductHomologation = Loadable({
  loader: () => import(/* webpackChunkName: 'productHomologation' */  '../src/pages/productHomologation'),
  loading: renderSpinner,
});

const UserManager = Loadable({
  loader: () => import(/* webpackChunkName: 'userManager' */  './pages/userManager'),
  loading: renderSpinner,
});

const ProductCampaign = Loadable({
  loader: () => import(/* webpackChunkName: 'productCampaign' */  '../src/pages/productCampaign'),
  loading: renderSpinner,
});

const ProductTypeCampaign = Loadable({
  loader: () => import(/* webpackChunkName: 'productCampaign' */  '../src/pages/productTypeCampaign'),
  loading: renderSpinner,
});

const ProductTypeCampaignTaskManager2 = Loadable({
  loader: () => import(/* webpackChunkName: 'productCampaign' */  '../src/pages/productTypeCampaignTaskManager2'),
  loading: renderSpinner,
});

const ProductTypeCampaignTaskManager1 = Loadable({
  loader: () => import(/* webpackChunkName: 'productCampaign' */  '../src/pages/productTypeCampaignTaskManager1'),
  loading: renderSpinner,
});

const ProductTypeCampaignSergen = Loadable({
  loader: () => import(/* webpackChunkName: 'productCampaign' */  './pages/productTypeCampaignSergen'),
  loading: renderSpinner,
});

const PerfectStore = Loadable({
  loader: () => import(/* webpackChunkName: 'productCampaign' */  './pages/perfectStore'),
  loading: renderSpinner,
});

const ProductAttributesManager = Loadable({
  loader: () => import(/* webpackChunkName: 'productCampaign' */  '../src/pages/productAttributesManager'),
  loading: renderSpinner,
});

const PriceAttributes = Loadable({
  loader: () => import(/* webpackChunkName: 'productCampaign' */  '../src/pages/priceAttributes'),
  loading: renderSpinner,
});

const CenterLocationManager = Loadable({
  loader: () => import(/* webpackChunkName: 'CenterLocationManager' */  '../src/pages/centerLocationManager'),
  loading: renderSpinner,
});

const HomologationManager = Loadable({
  loader: () => import(/* webpackChunkName: 'HomologationManager' */  './pages/sugestedHomologationManager'),
  loading: renderSpinner,
});

const TaskManager = Loadable({
  loader: () => import(/* webpackChunkName: 'productCampaign' */  '../src/pages/taskManager'),
  loading: renderSpinner,
});

//comentario para que funcione el git add
const ProductCodeTranslation = Loadable({
  loader: () => import(/* webpackChunkName: 'productCampaign' */  '../src/pages/productCodeTranslation'),
  loading: renderSpinner,
});

const ManHours = Loadable({
  loader: () => import(/* webpackChunkName: 'productCampaign' */  '../src/pages/manHours'),
  loading: renderSpinner,
});

const TypeCampaign = Loadable({
  loader: () => import(/* webpackChunkName: 'productCampaign' */  '../src/pages/typeCampaign'),
  loading: renderSpinner,
});

const HomologationWithAutoHomologation = Loadable({
  loader: () => import(/* webpackChunkName: 'productHomologation' */  '../src/pages/productHomologationWithAutoHomologation'),
  loading: renderSpinner,
});

const TaskManager2 = Loadable({
  loader: () => import(/* webpackChunkName: 'taskmanager2' */  '../src/pages/taskManager2'),
  loading: renderSpinner,
});

class App extends Component {
  constructor() {
    super()
    this.state = {
      version: packageJson.version || 'ERROR' //'2020.03.27-1'
    }
  }
  componentDidMount() {
    console.log('Versión: ' + this.state.version)
  }

  componentWillMount() { /*
    console.log('APP DEBUG', env.REACT_APP_DEBUG)
    console.log('APP ENV', env.REACT_APP_ENVIRONMENT)
    console.log('APP LOCALHOST ', env.REACT_APP_LOCALHOST)
    console.log('APP TEST', env.REACT_APP_TEST) */
    try { /*
      console.log("REACT_APP_LOCALHOST: " + env.REACT_APP_LOCALHOST);
      console.log("REACT_APP_TEST: " + env.REACT_APP_TEST);
      console.log("REACT_APP_DEV_TOKEN: " + env.REACT_APP_DEV_TOKEN); */
      const loginToken = env.REACT_APP_LOCALHOST || env.REACT_APP_TEST ? env.REACT_APP_DEV_TOKEN : this.props.location.hash.split('=')[1].split('&')[0]
      //console.log("loginToken: " + loginToken);
      this.props.authLogin(loginToken)
        .then()
        .catch(err => { console.log("error de login", err) })
    } catch (e) {
      console.log("ERROR EN APP authLogin:", e)
      window.location = env.REACT_APP_REDIRECT_URL
    }
  }

  reload = () => {
    window.location.reload();
  }


  render() {
    const { isAuthenticated, message } = { ...this.props }

    //const clear = require('clear-screen')
    //clear()

    return (

      <Container fluid={true}>
        <Row>
          <Col className="no-padding">
            {isAuthenticated ?
              <div>

                <CacheBuster>
                  {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                      return (
                        <div className="dr-page">

                          <div className="alert alert-warning" align="center" role="alert" style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); refreshCacheAndReload(); }}>
                            Estás usando una versión antigua! Haz click <a className="alert-link">aquí</a> para actualizar a la última versión!
                          </div>

                        </div>
                      )
                    }

                    return (
                      <div>
                        {/*console.log("Bundle version - ", global.appVersion)*/}
                      </div>
                    );
                  }}
                </CacheBuster>

                <Switch>
                  <Route exact path='/center_attributes' component={CenterAttributes} />
                  <Route exact path='/taskmanager_email_recs' component={TaskManagerEmailRecs} />
                  <Route exact path='/taskmanager_routes' component={TaskManagerRoutes} />
                  <Route exact path='/attribute_manager' component={AttributeManager} />
                  <Route exact path='/product_attributes' component={ProductAttributes} />
                  <Route exact path='/product_campaign' component={ProductCampaign} />
                  <Route exact path='/product_homologation' component={ProductHomologation} />
                  <Route exact path='/homologation_keeper_with_auto_homologation' component={HomologationWithAutoHomologation} />
                  <Route exact path='/users_keeper' component={UserManager} />
                  <Route exact path='/product_attributes_manager' component={ProductAttributesManager} />
                  <Route exact path='/price_attributes' component={PriceAttributes} />
                  <Route exact path='/center_location_manager' component={CenterLocationManager} />
                  <Route exact path='/sugested_homologation_manager' component={HomologationManager} />
                  {/*Mantenedores de campaña*/}
                  <Route exact path='/campaign_maintainer_task1' component={ProductTypeCampaignTaskManager1} /> {/*Mantnenedor de campaña Taskmanager 1*/}
                  <Route exact path='/product_type_campaign' component={ProductTypeCampaign} /> {/* no hay ruta asociada a eso (09/03/2023)*/}
                  <Route exact path='/product_type_campaign_sergen' component={ProductTypeCampaignSergen} /> {/* no hay ruta asociada a eso (09/03/2023)*/}
                  <Route exact path='/perfect_store' component={PerfectStore} />  {/* no hay ruta asociada a eso (09/03/2023)*/}
                  <Route exact path='/type_campaign' component={TypeCampaign} /> {/* no hay ruta asociada a eso (09/03/2023)*/}

                  <Route exact path='/taskmanager' component={TaskManager} /> {/*Mantnenedor de tareas Taskmanager 1*/}
                  <Route exact path='/manhours' component={ManHours} />
                  <Route exact path='/product_code_translation' component={ProductCodeTranslation} />
                  {/* taskmanager 2 */}
                  <Route exact path='/taskmanager2' component={TaskManager2} />  {/*Mantnenedor de tareas Taskmanager 2*/}
                  <Route exact path='/campaign_maintainer_task2' component={ProductTypeCampaignTaskManager2} /> {/*Mantnenedor de campaña Taskmanager 2*/}
                  <Route exact path='/taskmanager2_email_recs' component={TaskManager2EmailRecs} />
                  <Route component={ErrorPage} />
                </Switch>
              </div>
              :
              message ?
                <div>
                  {renderSpinner()}
                  Error de conexión
                  {/*
                  <Button color='info' onClick={this.reload}>
                    <FontAwesomeIcon className="title-icon" icon='undo-alt' size='2x' />Reintentar
                  </Button>
                */}
                  {renderError("Error de conexión", message)}
                </div>
                :
                <div>
                  <img className='logo-animation' src={logo} alt='' />
                </div>
            }
          </Col>
        </Row>
        <Alert
          effect='scale'
          position='bottom-right'
          stack={{ limit: 2 }}
          timeout={5000}
        />
      </Container>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    userProfile: state.userProfile,
    isAuthenticated: state.userProfile.isAuthenticated,
    message: state.userProfile.message,
  }
}

const mapDispatchToProps = {
  authLogin,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
