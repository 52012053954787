import initialState from '../initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerItemListCatalog, action) {
    switch (action.type) {
        case types.ITEM_LIST_CATALOG_FULFILLED:
            return {
                itemListCatalog: action.payload ? action.payload.data.results : [{}],
                isItemListCatalogLoaded: true
            }
        default:
            return state;
    }
}
