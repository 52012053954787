import initialState from '../../reducers/initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerCampaignPerfectStoreSubPillar, action) {

    switch (action.type) {
        case types.SUB_PILLAR_LIST_FULFILLED:
            return {
                ItemListSubPillar: action.payload ? action.payload : [{}],
            }
        default:
            return state;
    }
}
