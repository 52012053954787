import initialState from "../../reducers/initialState";
import * as types from 'actions/actionTypes'

export default function (state = initialState.reducerItemListCampFollowUserTypes, action) {

    
    switch (action.type) {
        // case types.ITEM_LIST_MAN_HOURS_FULFILLED:
        case types.FINAL_PRODUCT_CODE_LIST_FULFILLED:
            return {
                finalProductCodeItemListProductCodeTanslation: action.payload ? action.payload.data.results : [{}],
            }
        default:
            return state;
    }

}