import initialState from 'reducers/initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerInternalCurrentProduct, action) {
  switch (action.type) {
    case types.HOMOLOGATION_LIST_FULFILLED:
      return {
        currentItem: action.payload,    
      }
    case types.COMPONENTS_HOMOLOGATED_FULFILLED:
      return {
        wasHomologated: action.payload,    
      }
    default:
      return state;
  }
}
