import initialState from '../../reducers/initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerItemListTypeCampaign, action) {
    switch (action.type) {
        case types.ITEM_LIST_TYPE_CAMPAIGN_FULFILLED:
            return Object.assign({}, state, {
                itemListTypeCampaign: action.payload ? action.payload.data.results : [{}],
                isItemsLoadedTypeCampaign: true
            })
        default:
            return state;
    }
}
