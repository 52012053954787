import initialState from 'reducers/initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerCurrentItem, action) {
  switch (action.type) {
    case types.ITEM_SELECT:
      return {
        currentItem: action.payload,
        isItemSelected: true,
        isNewItem: !action.payload.PRODUCT_CODE ? true : false
      }
    case types.ITEM_SELECT_FULFILLED:
      return {
        currentItem: action.payload,
        isItemSelected: true,
        isNewItem: !action.payload.PRODUCT_CODE ? true : false
      }
    case types.ITEM_DESELECT:
      return {
        currentItem: false,
        isItemSelected: false
      }
    case types.ITEM_SELECT_OPEN_MODAL:
      return {
        currentItem: action.payload,
        isItemSelectedtoModal: true,
        isNewItem: !action.payload.PRODUCT_CODE ? true : false
      }
    case types.ITEM_DESELECT_CLOSE_MODAL:
      return {
        currentItem: action.payload,
        isItemSelectedtoModal: false
      }
    case types.ITEM_ADD_FULFILLED:
      return {
        itemAdded: action.payload.data.item,
        isItemAdded: true,
      }
    case types.ITEM_ADD_REJECTED:
      return {
        reason: (action.payload.response.data.reason.split('\'')[1] || action.payload.response.data.reason),
        currentItem: state.currentItem,
        isItemSelected: true,
        isNewItem: true
      }
    case types.ITEM_UPDATE_FULFILLED:

      return {
        itemUpdated: action.payload.data.item,
        isItemUpdated: true,
        currentItem: action.payload.data.item,
        isItemSelected: true,
      }
    case types.ITEM_UPDATE_REJECTED:
      return {
        reason: (action.payload.response.data.reason.split('\'')[1] || action.payload.response.data.reason),
        message: action.payload.message,
        currentItem: state.currentItem,
        isItemSelected: true,
      }
    case types.ITEM_DELETE_FULFILLED:
      return {
        itemDeleted: (JSON.parse(action.payload.config.data)).item,
        isItemDeleted: true
      }
    case types.ITEM_DELETE_REJECTED:
      return {
        reason: (action.payload.response.data.reason.split('\'')[1] || action.payload.response.data.reason),
        message: action.payload.message,
        currentItem: state.currentItem,
        isItemSelected: true,
      }
    case types.ITEM_NEW:
      return {
        itemNew: action.payload,
        isItemNew: true,
      }
    case types.ITEM_ADD:
      return state;
    case types.ITEM_DELETE:
      return state;
    case types.ITEM_UPDATE:
      return state;
    default:
      return state;
  }
}
