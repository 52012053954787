import initialState from '../../reducers/initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerItemListManufacturerImgQualityLevel, action) {
    switch (action.type) {
        case types.ITEM_LIST_MANUFACTURERS_IMG_QUALITY_LEVEL_FULFILLED:
            return {
                ItemListManufacturerImgQualityLevel: action.payload ? action.payload.data.results : [{}],
                isItemListManufacturerImgQualityLevelLoaded: true,
            }
        default:
            return state;
    }
}
