import React, { Component } from "react"
import ReactLoading from 'react-loading';
import Alert from 'react-s-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DefaultErrorPage, Icon } from "tabler-react";
import 'tabler-react/dist/Tabler.css';


//import runtimeEnv from '@mars/heroku-js-runtime-env';
const env = process.env;

export function debugLogger(...args) {
  if (env.REACT_APP_DEBUG) { console.log(...args) }
}

export function isNil(...value) {
  if (value) {
    return value
  } else {
    return "Sin información"
  }
}

export function renderSpinner() {
  return (
    <ReactLoading
      className='spinCenter'
      type='spinningBubbles'
      color='#444'
    />
  )
}

export function renderError(title, message) {
  Alert.error(
    <div>
      <FontAwesomeIcon className="s-alert-icon" icon='exclamation-triangle' size='3x' />
      <div className="">
        <h5>{title}</h5>
        <strong>{message}</strong>
      </div>
    </div>
    , { position: 'bottom', effect: 'slide', timeout: 10000 })
}

export function renderAlertSucess(message, position = 'bottom-right') {
  Alert.success(
    <div>
      <FontAwesomeIcon icon='check' size='lg' />
      <span className="s-alert-message">{message}</span>
    </div>
    , { position: position, effect: 'scale', timeout: 10000 }
  )
}

export function renderAlertWarning(message) {
  Alert.warning(
    <div>
      <FontAwesomeIcon className="s-alert-icon" icon='exclamation-triangle' size='2x' />
      <span className="s-alert-message">{message}</span>
    </div>
    , { position: 'bottom-right', effect: 'scale', timeout: 10000 }
  )
}

export function renderAlertErrorSmall(message, timeout=10000) {
  Alert.error(
    <div>
      <FontAwesomeIcon icon='exclamation-triangle' size='lg' />
      <span className="s-alert-message">{message}</span>
    </div>
    , { position: 'bottom-right', effect: 'scale', timeout: timeout }
  )
}

export function renderAlertError(title, message, position = 'bottom') {
  Alert.error(
    <div>
      <FontAwesomeIcon className="s-alert-icon" icon='exclamation-triangle' size='3x' />
      <div className="">
        <h5>{title}</h5>
        <strong>{message}</strong>
      </div>
    </div>
    , { position: position, effect: 'scale', timeout: 10000 }
  )
}

export function sizeHelper() {
  return (
    <div>
      <div className="d-block d-sm-none">XS</div>
      <div className="d-none d-sm-block d-md-none">SM</div>
      <div className="d-none d-md-block d-lg-none">MD</div>
      <div className="d-none d-lg-block d-xl-none">LG</div>
      <div className="d-none d-xl-block">XL</div>
    </div>
  )
}

export function generateAutoCompleteItems(data, option = 'single') {
  const dataAutocomplete = []
  if (option === 'single') {
    Object.values(data).filter(value => value).forEach(value => {
      dataAutocomplete.push({ value })
    });
  }
  if (option === 'array') {
    data.forEach(obj => {
      Object.keys(obj).forEach(key => {
        if (dataAutocomplete[key]) {
          if (!dataAutocomplete[key].some(x => x.value === obj[key])) {
            dataAutocomplete[key].push({ value: obj[key] })
          }
        } else {
          dataAutocomplete[key] = []
          dataAutocomplete[key].push({ value: obj[key] })
        }
      })
    });
  }
  return dataAutocomplete
}

export function removeNonNumericCharacters(text) {
  return text.replace(/[^0-9]/g, '');
}


export class ErrorPage extends Component {
  render() {
    return (
      <div style={{ padding: '20%' }}>
        <DefaultErrorPage
          title={<span><Icon name="alert-triangle" /> 404</span>}
          subtitle={'Subtitulo'}
          details={'Detalles soporte@datamind.cl'}
          action={'Volver'}
        />
      </div>
    )
  }
}