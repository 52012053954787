import initialState from '../initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerItemListManufacturersAvailableToAdd, action) {
    switch (action.type) {
        case types.ITEM_LIST_MANUFACTURERS_AVAILABLE_TO_ADD_FULFILLED:
            return {
                itemListManufacturersAvailableToAdd: action.payload ? action.payload.data.results : [{}],
                isItemListManufacturersAvailableToAddLoaded: true,
            }
        default:
            return state;
    }
}
