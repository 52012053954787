import * as api from 'api_service'
import * as types from 'actions/actionTypes'

export function authLogin(token) {
  //console.log("TOKEN: ", token);
  const request = api.get('/login?id=' + token);
  //console.log("request:", request)
  return {
    type: types.AUTH_LOGIN,
    payload: request
  };
}
