import initialState from 'reducers/initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerHomologationProductList, action) {
  switch (action.type) {
    case types.INTERNAL_PRODUCT_DESELECT:
      let newExternalProductsList = state.externalProductList.map((externalProduct) => {
        externalProduct.status = false
        return externalProduct
      })
      return {
        ...state,
        externalProductList: newExternalProductsList
      }
    case types.INTERNAL_PRODUCT_SELECT:
      let internalProductSelected = action.payload[0]
      let newExternalsProductList = state.externalProductList.map((externalProduct) => {
        if (internalProductSelected.PRODUCT_CODE === externalProduct.Codigo_interno) {
          externalProduct.status = true
        }
        return externalProduct
      })
      return {
        ...state,
        externalProductList: newExternalsProductList
      }
    case types.EXTERNAL_PRODUCT_DESELECT:
      let newInternalProductList = state.internalProductList.map((internalProduct) => {
        internalProduct.status = false
        return internalProduct
      })
      return {
        ...state,
        internalProductList: newInternalProductList
      }
    case types.EXTERNAL_PRODUCT_SELECT:
      let externalProductSelected = action.payload[0]
      if (externalProductSelected.Codigo_interno) {
        let newInternalProductList = state.internalProductList.map((internalProduct) => {
          if (externalProductSelected.Codigo_interno === internalProduct.PRODUCT_CODE) {
            internalProduct.status = true
          }
          return internalProduct
        })
        return {
          ...state,
          internalProductList: newInternalProductList
        }
      } else {
        return state;
      }
    case types.DELETE_HOMOLOGATION_FULFILLED:
      let newExternalProductsLists = state.externalProductList.map((externalProduct) => {
        if (externalProduct.g_external_product_relationship_id === action.payload.data.g_external_product_relationship_id) {
          externalProduct = action.payload.data
          externalProduct.status = false
        }
        return externalProduct
      })
      return {
        ...state,
        externalProductList: newExternalProductsLists
      }

    case types.NEW_HOMOLOGATION_FULFILLED:
    case types.BLOCK_EXTERNAL_PRODUCT_FULFILLED:
      let newExternalProductList = state.externalProductList.map((externalProduct) => {
        if (externalProduct.g_external_product_relationship_id === action.payload.data.g_external_product_relationship_id) {
          externalProduct = action.payload.data
        }
        return externalProduct
      })
      return {
        ...state,
        externalProductList: newExternalProductList
      }
    case types.HOMOLOGATION_PRODUCT_LIST_FULFILLED:
      /*const newExternalProducts = []
      
      action.payload.data.externalProducts.forEach( extProduct => {
        extProduct.g_external_product_relationship_id.split(';').forEach( (singleExtProdRelId, index) => {
          newExternalProducts.push(
            { ...extProduct, EAN: extProduct.EAN.split(';')[index], EXTERNAL_PRODUCT_RELATIONSHIP_ID: singleExtProdRelId }
          )
        })
      })*/
      return {
        internalProductList: action.payload.data.internalProducts,
        externalProductList: action.payload.data.externalProducts,
        productListLoaded: true,
      }
    case types.HOMOLOGATION_PRODUCT_LIST_REJECTED:
      return {
        internalProductList: [],
        externalProductList: [],
        isItemsLoaded: false,
        reason: action.payload.message ? action.payload.message : 'No se pudieron Obtener Datos por favor intente denuevo'
      }
    case types.HOMOLOGATION_PRODUCT_LIST:
      return {
        state
      }
    default:
      return state;
  }
}
