import initialState from '../initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerItemListManufacturers, action) {
    switch (action.type) {
        case types.ITEM_LIST_MANUFACTURERS_FULFILLED:
            return {
                itemListManufacturers: action.payload ? action.payload.data.results : [{}],
                isItemListManufacturersLoaded: true,
            }
        default:
            return state;
    }
}
