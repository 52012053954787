import initialState from 'reducers/initialState';
import * as types from 'actions/actionTypes';
import download from 'downloadjs'

export default function(state = initialState.reducerFile, action) {
  switch (action.type) {
    case types.FILE_MODAL:
      return {
        isUploadModal: !state.isUploadModal
      }
    case types.FILE_UPLOAD_FULFILLED:
      return {
        isUploadingFile: false,
        isUploaded: true
      }
    case types.FILE_UPLOAD_REJECTED:
      return {
        isUploadingFile: false,
        isUploadModal: true,
        reason: (action.payload.response.data.reason.split('\'')[1] || action.payload.response.data.reason)
      }
    case types.FILE_UPLOAD_PENDING:
      return {
        isUploadingFile: true,
        isUploadModal: true
      }
    case types.FILE_DOWNLOAD_FULFILLED:
      let fileName = 'Datamind_'+action.payload.config.url.split('/').splice(-1)[0]+'_'+Math.floor(Date.now()/100)%15000000000+'.xlsx'
      download(action.payload.data, fileName);
      return {
        isDownloadingFile: false
      }
    case types.FILE_DOWNLOAD_REJECTED:
      return {
        isDownloadingFile: false,
        reason: (action.payload.response.data.reason.split('\'')[1] || action.payload.response.data.reason)
      }
    case types.FILE_DOWNLOAD_PENDING:
      return {
        isDownloadingFile: true
      }
    default:
      return state;
  }
}
