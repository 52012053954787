import initialState from '../../reducers/initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerCampaignPerfectStorePillar, action) {
    switch (action.type) {
        case types.PILLAR_LIST_FULFILLED:
            return {
                ItemListPillar: action.payload ? action.payload : [{}],
            }
        default:
            return state;
    }
}
