import initialState from '../initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerItemListCfgManufacturersToLoad, action) {
    switch (action.type) {
        case types.ITEM_LIST_CFG_MANUFACTURERS_TO_LOAD_FULFILLED:
            return {
                itemListCfgManufacturersToLoad: action.payload ? action.payload.data.results : [{}],
                isItemListCfgManufacturersToLoadLoaded: true,
            }
        default:
            return state;
    }
}
