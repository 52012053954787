import initialState from '../../reducers/initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerItemListCampTaskUserTypes, action) {
    switch (action.type) {
        case types.ITEM_LIST_CAMP_TASK_USER_TYPES_FULFILLED:
            return {
                ItemListCampTaskUserTypes: action.payload ? action.payload : [{}],
            }
        default:
            return state;
    }
}
