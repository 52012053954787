import initialState from "../../reducers/initialState";
import * as types from 'actions/actionTypes'

export default function (state = initialState.reducerItemListCampFollowUserTypes, action) {


    switch (action.type) {
        case types.INITIAL_PRODUCT_CODE_LIST_FULFILLED:
            return {
                initialProductCodeItemListProductCodeTanslation: action.payload ? action.payload.data.results : [{}],
            }
        default:
            return state;
    }

}