import initialState from '../initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reducerItemListCatalog, action) {

    switch (action.type) {
        case types.ITEM_LIST_SALE_TYPES_FULFILLED:
            return {
                itemListSaleTypes: action.payload ? action.payload.data.results : [{}],
                isItemListSaleTypesLoaded: true
            }
        default:
            return state;
    }
}
