import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form'

import loginReducer from '../reducers/authReducer'

import ItemsReducer from "../reducers/items/reducerItemList";
import CurrentItemReducer from "../reducers/items/reducerCurrentItem";
import FileReducer from "../reducers/files/reducerFile";
import HomologationProductsReducer from "../reducers/homologation/reducerHomologationProductList"
import InternalCurrentProduct from "../reducers/homologation/reducerInternalCurrentProduct"
import ExternalCurrentProduct from "../reducers/homologation/reducerExternalCurrentProduct"
import HomologationProcess from "../reducers/homologation/reducerHomologationProcess"
import HomologationList from "../reducers/homologation/reducerHomologationList"
import GridReducer from "../reducers/filters/gridReducer"
import statusReducer from '../reducers/appStatusReducer'
import queriesReducer from '../reducers/filters/reducer_queries'
import QueryFilterReducer from '../reducers/filters/reducer_query_filter'
import CampaignTypeTask from '../reducers/campaign/reducerCampaignTypeTask'
import ItemListCampTaskUserTypes from '../reducers/campaign/reducerCampaignTaskUserTypes'
import ItemListCampFollowUserTypes from '../reducers/campaign/reducerCampaignsFollowUserTypes'
import ItemListManHours from '../reducers/manHours/reducerManHours'
import ItemListManufacturers from "../reducers/taskManager/reducerTaskManagerManufacturer"
import ItemListManufacturersAvailableToAdd from "../reducers/taskManager/reducerTaskManagerManufacturerAvailableToAdd"
import ItemListCatalog from "../reducers/taskManager/reducerTaskManagerCatalog"
import CurrentItemCatalogAddParams from "../reducers/taskManager/reducerTaskManagerCatalogAddParams"
import ItemListSaleTypes from "../reducers/taskManager/reducerTaskManagerSaleTypes"
import ItemListAlternativeList from "../reducers/taskManager/reducerTaskManagerAlternativeList"
import ItemListPillar from "../reducers/campaign/reducerCampaignPerfectStorePillar"
import ItemListSubPillar from "../reducers/campaign/reducerCampaignPerfectStoreSubPillar"
import ItemListCatalogDimensions from "../reducers/taskManager/reducerTaskManagerCatalogDimensions"
import ItemListCfgManufacturersToLoad from "../reducers/taskManager/reducerTaskManagerCfgManufacturerToLoad"
import ItemListManufacturerUserTypes from "../reducers/taskManager/reducerTaskManagerManufacturerUserTypes"
import ItemListManufacturerImgQualityLevel from "../reducers/taskManager/reducerTaskManagerManufacturerImgQualityLevel"
import ItemListTypeCampaign from "../reducers/campaign/reducerTypeCampaign"
import itemListCampaignTypeGroupVisibility from "./campaign/reducerCampaignTypeGroupVisibility"
import itemListProductCodeTanslation from "../reducers/productCodeTranslation/reducerProductCodeTranslation"
import itemListProductCodeTanslationRetails from "../reducers/productCodeTranslation/reducerProductCodeTranslationRetailers"
import itemListGetInitialProductCodeInProductCodeTanslationRetails from "../reducers/productCodeTranslation/reducerGetInitialProductCodeInProductCodeTanslationRetails"
import itemListGetFinalProductCodeInProductCodeTanslationRetails from "../reducers/productCodeTranslation/reducerGetFinalProductCodeInProductCodeTanslationRetails"
import ItemListManufacturerUserRoles from "../reducers/taskManager/reducerTaskManagerUserRoles.js"
import ItemListLocation from "../reducers/items/reducerIlemListLocation"
import ItemListLocationAcceptedOrRejected from "../reducers/items/reducerCenterLocationAcceptedOrRejected"

const rootReducer = combineReducers({
  userProfile: loginReducer,
  appStatus: statusReducer,
  form: formReducer,
  ItemsReducer,
  CurrentItemReducer,
  FileReducer,
  HomologationList,
  HomologationProductsReducer,
  InternalCurrentProduct,
  ExternalCurrentProduct,
  HomologationProcess,
  grids: GridReducer,
  queriesReducer,
  queryFilter: QueryFilterReducer,
  CampaignTypeTask,
  ItemListCampTaskUserTypes,
  ItemListCampFollowUserTypes,
  ItemListManHours,
  ItemListManufacturers,
  ItemListManufacturersAvailableToAdd,
  ItemListCatalog,
  ItemListCatalogDimensions,
  CurrentItemCatalogAddParams,
  ItemListSaleTypes,
  ItemListAlternativeList,
  ItemListLocation,
  ItemListLocationAcceptedOrRejected,
  ItemListManufacturerUserRoles,
  ItemListPillar,
  ItemListSubPillar,
  ItemListCfgManufacturersToLoad,
  ItemListManufacturerUserTypes,
  ItemListManufacturerImgQualityLevel,
  ItemListTypeCampaign,
  itemListCampaignTypeGroupVisibility,
  itemListProductCodeTanslation,
  itemListProductCodeTanslationRetails,
  itemListGetInitialProductCodeInProductCodeTanslationRetails,
  itemListGetFinalProductCodeInProductCodeTanslationRetails,
});

export default rootReducer;
