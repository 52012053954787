import initialState from '../initialState';
import * as types from 'actions/actionTypes';

export default function (state = initialState.reduceritemListCampaignTypeGroupVisibility, action) {
    switch (action.type) {
        case types.ITEM_LIST_CAMPAIGN_TYPE_GROUP_VISIBILITY_FULFILLED:
            return Object.assign({}, state, {
                itemListCampaignTypeGroupVisibility: action.payload ? action.payload.data.results : [{}],
                isItemsLoadedCampaignTypeGroup: true
            })
        default:
            return state;
    }
}
