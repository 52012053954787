import initialState from 'reducers/initialState';
import * as types from 'actions/actionTypes';

export default function(state = initialState.reducerHomologationProcess, action) {
  const stepProcess = [ 'INIT', 'FIRST_SELECT', 'HOMOLOGATED_TRUE',
                        'HOMOLOGATED_FALSE', 'SECOND_SELECT']
  switch (action.type) {
    case types.INTERNAL_PRODUCT_SELECT:
      return{
        external_selected: state.external_selected,
        internal_selected: true,
        stepProcess: stepProcess[state.external_selected?4:1]
      }
    case types.EXTERNAL_PRODUCT_SELECT:
      return{
        external_selected: true,
        internal_selected: state.internal_selected,
        stepProcess: stepProcess[state.internal_selected?4:1]
      }
    case types.INTERNAL_PRODUCT_DESELECT:
      return{
        external_selected: state.external_selected,
        internal_selected: false,
        stepProcess: stepProcess[state.external_selected?1:0]
      }
    case types.EXTERNAL_PRODUCT_DESELECT:
      return{
        external_selected: false,
        internal_selected: state.internal_selected,
        stepProcess: stepProcess[state.internal_selected?1:0]
      }
    case types.NEXT_STEP:
      return {
        stepProcess: stepProcess[stepProcess.indexOf(state.stepProcess)+1]
      }
    case types.RESET_PROCESS:
      return {
        stepProcess: stepProcess[0],
        external_selected: false,
        internal_selected: false,
      }
    case types.FIRST_SELECT:
      return {
        stepProcess: stepProcess[1],
      }
    case types.HOMOLOGATED_TRUE:
      return {
        stepProcess: stepProcess[2]
      }
    case types.HOMOLOGATED_FALSE:
    return {
      stepProcess: stepProcess[3]
    }
    case types.SECOND_SELECT:
      return {
        stepProcess: stepProcess[4]
      }
    default:
      return state;
  }
}
